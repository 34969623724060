<template>
  <div>
    Terminating your session...
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'Logout',
  created () {
    this.logout().then(() => {
      this.$router.push({name: 'homepage'})
    })
  },
  methods: {
    ...mapActions('auth', ['logout'])
  }
}
</script>

<style scoped>

</style>